<template>
    <div>
        <ValidationObserver ref="filterForm">
        <b-row>
            <b-col cols="12" md="4">
                <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('academic_year')">
                        <academic-years-selectbox
                            v-model="filter.academic_year"
                            :validate-error="errors[0]"
                        >
                    </academic-years-selectbox>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="12" md="4">
                <b-form-group :label="$t('semester')">
                    <parameter-selectbox
                        v-model="filter.semester"
                        code="semester"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
                <b-form-group :label="$t('report_type')">
                    <multi-selectbox
                        :allow-empty="false" :multiple="false"
                        :options="responseTypeOptions" v-model="responseType"
                    ></multi-selectbox>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-button variant="primary" @click="getReport">
                    {{ $t('get_report').toUpper() }}
                </b-button>
            </b-col>
        </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox"
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"
import LecturerReportService from "@/services/LecturerReportService"
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox.vue";
import qs from "qs"
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox.vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
    components: {
        AcademicYearsSelectbox,
        SemestersSelectbox,
        MultiSelectbox,
        ParameterSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            filter: {},
            responseType: 'page',
            responseTypeOptions: [
                {value: 'page', text: this.$t('page')},
                {value: 'excel', text: this.$t('excel')},
                {value: 'pdf', text: 'PDF'}
            ]
        }
    },
    methods: {
        async getReport() {
            const isValid = await this.$refs.filterForm.validate()
            if (isValid) {
                const config = {
                    params: {
                        filter: {
                            ...this.filter
                        },
                        response_type: this.responseType,

                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
                if (this.responseType != 'page') {
                    config.responseType = 'arraybuffer'
                }

                LecturerReportService.getGradeDistributionList(config)
                    .then((response) => {
                        if (this.responseType == 'page') {
                            this._openFile(response);
                        } else {
                            this._downloadFile(response, this.$t('Grade-Entry-Report'));
                        }
                    })
                    .catch((error) => {
                        this.showErrors(error)
                    })
            }
        }
    }
}
</script>
