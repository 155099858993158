// Plugins
import i18n from '@/plugins/i18n'

export default function() {
    let reportTypes = []

    // Student Reports
   /* reportTypes.push({
        value: 'student-report',
        text: i18n.t('student_report'),
        permission: 'studentslot_studentslotsexcelexport'
    })*/


    // Educator Reports
    // reportTypes.push({
    //     value: 'educator-report',
    //     text: i18n.t('course_history'),
    //     permission: 'section_instructorlecturereportexcelexport'
    // })

    // Faculty Course Based Grade Entry Reports
    reportTypes.push({
        value: 'faculty-course-grade-entry-report',
        text: i18n.t('faculty_course_grade_entry_report'),
        permission: 'faculty_facultycoursegradeentryreport'
    })

    // Survey Reports
    reportTypes.push({
        value: 'survey-report',
        text: i18n.t('survey_report'),
        //permission: 'studentreport_registrationnumbersbyfaculty'
    })

    // Exam Schedule
/*
    reportTypes.push({
        value: 'exam-schedule-report',
        text: i18n.t('exam_schedule_report'),
        permission: 'section_instructorlecturereportexcelexport'
    })
*/

  /*  reportTypes.push({

    })*/

    reportTypes.push({
        value: 'virtual-internships-report',
        text: i18n.t('virtual_internship_report'),
        permission: 'studentreport_virtualinternshipsreport'
    })

    reportTypes.push({
        value: 'attendance-entry-tracking-report',
        text: i18n.t('attendance_entry_tracking_report'),
        permission: 'dailyattendance_entrytrackingreport'
    })

    return reportTypes
}


