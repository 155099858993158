import Api from '@/services/Index'

const getStudentReport = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/student/slots/slot-number-report', {responseType: 'arraybuffer'})
}

const getLecturerReport = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/sections/instructor-lecture-report/excel-export', {responseType: 'arraybuffer'})
}


const getGradeEntryReportByFaculty = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/lecturer/reports/faculty-grade-entry-report', config)
}

const getGradeEntryReportByCourse = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/lecturer/reports/course-grade-entry-report', config)
}

const getCourseListReport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/exam/schedules/course-list-report', config)
}

const getGradeEntryReportByFacultyCourse = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/lecturer/reports/faculty-course-grade-entry-report', config)
}

const getGradeDistributionList = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT
    return Api.get('/student/reports/grade-distribution-list-report', config)
}
const studentVirtualInternshipReport = async () => {
    return Api.get('/student/reports/virtual-internships-report', {responseType:"arraybuffer"})
}
export default {
    getStudentReport,
    getLecturerReport,
    getGradeEntryReportByFaculty,
    getGradeEntryReportByCourse,
    getCourseListReport,
    getGradeEntryReportByFacultyCourse,
    getGradeDistributionList,
    studentVirtualInternshipReport
}
