<template>
    <div>
        <b-row>
            <b-col cols="12" md="6">
                <b-form-group :label="$t('report_type')">
                    <multi-selectbox
                        :allow-empty="false" :multiple="false"
                        :options="responseTypeOptions" v-model="responseType"
                    ></multi-selectbox>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group class="mb-0">
                    <b-button variant="primary" @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox.vue";
import AttendanceService from "@/services/AttendanceService";
import qs from "qs";

export default {
    components: {MultiSelectbox},

    data() {
        return {
            filter: {},
            responseType: 'page',
            responseTypeOptions: [
                {value: 'page', text: this.$t('page')},
                {value: 'excel', text: this.$t('excel')},
                {value: 'pdf', text: 'PDF'}
            ]
        }
    },
    methods: {
        getReport() {
            const config = {
                params: {
                    filter: {
                        ...this.filter
                    },
                    response_type: this.responseType
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }

            if (this.responseType != 'page') {
                config.responseType = 'arraybuffer'
            }

            AttendanceService.entryTrackingReport(config).then(this.callback).catch(e => {
                this.showErrors(e, this.$refs.form, false);
            }).finally(() => {
                this.processing = false;
            });
        },
        callback(response) {
            if (this.responseType == 'page') {
                this._openFile(response);
            } else if (this.responseType == 'excel') {
                this._downloadFile(response,  'attendance_entry_tracking_report.xlsx');
            } else if (this.responseType == 'pdf') {
                this._downloadFile(response, 'attendance_entry_tracking_report.pdf');
            }
        }
    }
}
</script>
